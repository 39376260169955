


















































































































































































import Vue, { VueConstructor } from 'vue'
import OtpModal from '@/components/OtpModal.vue'
import OvaCard from '../../components/OvaCard.vue'
import uiState from '../../../../ui-state'
import Account, { AccountType } from '../../../../store/models/Account'
import { mapGetters } from 'vuex'
import Organization from '../../../../store/models/Organization'
import KkiapayAccount, {
  KkiapayAccountType
} from '@/store/models/KkiapayAccount'

interface ComponentData {
  amount: number | null
  otm: string
  showOtpModal: boolean
  file: any
  fileName: string
  uiState: any
  account: string
  showSuccessModal: boolean
  options: Array<Record<string, any>>
  otmOptions: Array<Record<string, any>>
  specialAccounts: Array<Account>
  virtualReserveOtm: string
  disturbementOtm: string
  errorMessage: string
  successMessage: string
}

interface ReserveAccountCreditInterface {
  amount: number | null
  productId: string | undefined
  otm: string
  otp?: string
  file?: File
}
export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      otpModal: InstanceType<typeof OtpModal>
      formValidator: Vue & { validate: () => Promise<any> }
    }
  }
>).extend({
  components: {
    OtpModal,
    OvaCard
  },
  data(): ComponentData {
    return {
      amount: null,
      otm: 'mtn',
      showOtpModal: false,
      file: null,
      fileName: '',
      uiState: uiState([
        'validating',
        'validating_otp',
        'error_request_otp',
        'error_no_otp_user',
        'error_already_validated',
        'error_low_balance',
        'created'
      ]),
      account: '',
      showSuccessModal: false,
      options: [
        {
          title: 'Tous les OTM',
          data: 'tous'
        },
        {
          title: 'Moov',
          data: 'moov'
        },
        {
          title: 'MTN',
          data: 'mtn'
        }
      ],
      otmOptions: [
        {
          label: 'Moov',
          value: 'moov'
        },
        {
          label: 'MTN',
          value: 'mtn'
        }
      ],
      specialAccounts: [],
      virtualReserveOtm: 'tous',
      disturbementOtm: '',
      errorMessage: '',
      successMessage: ''
    }
  },

  computed: {
    ...mapGetters('product', ['getCurrentProduct']),
    ...mapGetters('auth', ['user']),
    cardSubtitle(this: any) {
      return `Solde au ${this.$options.filters.date(
        new Date(),
        'DD/MM/YYYY à HH:mm'
      )}`
    },
    accounts(): Array<Account> {
      return this.specialAccounts.filter((value: any) => {
        this.formatAccountNamefrench(value)
        return value.type == AccountType.RESERVE
      })
    },
    validating(): boolean {
      return this.uiState.is('validating')
    },
    verifyAmount(): any {
      const kkAccount = KkiapayAccount.query()
        .where('productId', this.getCurrentProduct.id)
        .where('type', KkiapayAccountType.DEFAULT)
        .first()
      const prop = `${this.otm}Balance`
      return {
        numeric: true,
        minValue: 1,
      }
    },
    organization(this: any): Organization {
      return Organization.loggedIn() as Organization
    },
    disturbementBalance(this: any): number {
      const kkAccount = KkiapayAccount.query()
        .where('productId', this.getCurrentProduct.id)
        .where('type', KkiapayAccountType.DEFAULT)
        .first()
      if (this.disturbementOtm == 'moov') return kkAccount?.moovBalance ?? 0
      else if (this.disturbementOtm == 'mtn') return kkAccount?.mtnBalance ?? 0
      else return kkAccount?.balance ?? 0
    },
    virtualReserveBalance(this: any): number {
      let accounts
      if (this.virtualReserveOtm == 'tous')
        accounts = this.specialAccounts.filter((value: any) => {
          return value.type == AccountType.RESERVE
        })
      else
        accounts = this.specialAccounts.filter((value: any) => {
          return (
            value.type == AccountType.RESERVE &&
            value.otm == this.virtualReserveOtm
          )
        })

      const valeurInitiale = 0
      if (accounts.length != 0) {
        const result = accounts.reduce(
          (accumulateur: number, valeurCourante: any) =>
            accumulateur + valeurCourante?.balance,
          valeurInitiale
        )
        console.log('result', result)
        return !isNaN(result) ? result : 0
      }
      return 0
    }
  },

  methods: {
    formatAccountNamefrench(account: any) {
      const accountTypeEquiv: any = {
        reserve: 'Réserve'
      }
      account.frenchName = account.name
      account.frenchName = account.frenchName.replace(/_/g, ' ')
      account.frenchName = account.frenchName.replace(
        account.type.toUpperCase(),
        accountTypeEquiv[`${account.type}`]
      )
    },
    onFileChanged($event: any): any {
      this.file = $event.target.files[0]
      if (this.file) this.fileName = this.file.name
    },
    validate(otp?: string): any {
      this.showOtpModal = false

      console.log('this is otp', otp)

      this.$refs.formValidator.validate().then((isValid: boolean) => {
        if (isValid) {
          this.successMessage = ''
          this.errorMessage = ''
          this.showOtpModal = true
          if (!otp) {
            this.uiState.set('validating_otp')
          } else this.uiState.set('validating')

          const data: ReserveAccountCreditInterface = {
            amount: this.amount,
            productId: this.getCurrentProduct.id,
            otm: this.otm,
            otp: otp
          }

          data['file'] = this.file
          Account.api()
            .creditReserveAccount(data)
            .then(async (value: any) => {
              await Account.api()
                .specialAccounts(this.getCurrentProduct.id)
                .then((response: any) => {
                  this.specialAccounts = response.response.data
                })
              this.resetField()
              this.showSuccessModal = true
              this.showOtpModal = false
              this.uiState.set('created', 'Transaction créer')
              this.successMessage =
                'Votre transaction a été effectuée avec succès'
            })
            .catch((error: any) => {
              if (error.response) {
                switch (error.response.status) {
                  case 400:
                    if (error.response.data.code === 'no_otp_user') {
                      this.uiState.set('error_no_otp_user')
                    } else if (error.response.data.code === 'otp_invalid') {
                      this.$refs.otpModal.setError(
                        'Le code otp entré est invalide'
                      )
                      this.uiState.set('error_request_otp')
                    }
                    break
                  case 403:
                    if (error.response.data.code === 'need_confirmation_otp') {
                      this.uiState.set('error_request_otp')
                    } else if (
                      error.response.data.code === 'already_validated'
                    ) {
                      this.uiState.set(
                        'error_already_validated',
                        error.response.data.validatedBy
                      )
                      this.showOtpModal = false
                    }
                    break
                  default:
                    this.showOtpModal = false
                    this.errorMessage =
                      'Echec de votre requête, veuillez réessayer'
                }
              }
            })
        }
      })
    },
    resetField(): any {
      this.amount = null
      this.otm = 'mtn'
      this.file = null
      this.fileName = ''
    }
  },

  async mounted() {
    KkiapayAccount.api().fetchAll()
    await Account.api()
      .specialAccounts(this.getCurrentProduct.id)
      .then((response: any) => {
        this.specialAccounts = response.response.data
      })
  }
})
